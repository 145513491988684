.streamBox {
    max-width: 300px;
}

@media screen and (min-width: 760px) {
    .streamBox {
        margin-top:10vh!important;
        max-width: 350px;    
    }
}

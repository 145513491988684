
.brandTitle {
  font-size: 2rem;
}

.socialMedia {
 display:flex;
 flex-direction: row;
 position: relative;
 top:0.17rem;
}


@media (min-width: 992px) {
  .socialMedia {
    display:none;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 0.7rem;
    
  }
  .contactLink {
    margin-right: 2rem;
  }
}

.inst-ikon {
  margin-right: 0rem;
  margin-left: 0.75rem;
}


.navbar-brand {
  padding-left:1rem;
}

.navbar-toggle {
    width: 100%;
    float: none;
    margin-right: 0;
}
  
.nav-link {
    padding-right: 0rem!important;
}

.fa-instagram {
    font-size: 1.5em;
}

.fa-facebook {
    font-size: 1.5em;
}

@media (min-width: 992px) {
  .navbar-brand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding-left:0px;
  }
}
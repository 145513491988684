#whenIWakeUp {
    color: grey!important;
    font-size: 31px!important;
    margin-bottom:50px;   
}

@media (max-width: 767px) {
    #whenIWakeUp {
        line-height: 2rem!important;
    }
}

@media (min-width: 768px) {
    #whenIWakeUp { 
        margin-bottom:25px!important; 
    }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
    .redJacket {
        margin-top:60px!important;
    }
}
@media screen and (max-width: 1600px) and (min-width: 1200px) {
    .redJacket {
        width:95%;
        float: right;
    }
}


@media screen and (max-width: 1600px) {
    #topContainerMusic {
        margin-top: 2rem;   
    }
}

@media (min-width: 1600px) {
    #topContainerMusic {
        margin-top:3rem!important;   
    }
}

@media (min-width: 2161px) {
    #topContainerMusic {
    margin-top:10rem!important;
    }
}


